export default function Layout({children}) {
  return (
    <div className={"min-h-screen w-full bg-slate-800 font-sans"}>
      <div className={"py-3 border-b-2 border-slate-500"}>
        <div className={"container"}>
          <div className={"text-slate-200 text-xl font-medium"}>Serverless Conductor Demo</div>
          <div className={"text-slate-500 text-lg font-medium hover:text-slate-400"}><a href={"https://www.alexkearns.co.uk"}>alexkearns.co.uk</a></div>
        </div>
      </div>
      <div className={"container"}>
        {children}
      </div>
    </div>
  )
}