import Layout from "../components/layout";
import {useState} from "react";
import Shapes from "../components/shapes"
import toast from "react-hot-toast";

// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
const dec2hex = dec => {
  return dec.toString(16).padStart(2, "0")
}

// generateId :: Integer -> String
const generateId = len => {
  const arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

const userId = generateId(6)

export default function Root() {
  const [shape, setShape] = useState(null)
  const [colour, setColour] = useState(null)

  const chooseCloud = () => {
    setShape("cloud")
  }

  const chooseTicket = () => {
    setShape("ticket")
  }

  const chooseMegaphone = () => {
    setShape("megaphone")
  }

  const chooseRed = () => {
    setColour("red")
  }

  const chooseYellow = () => {
    setColour("yellow")
  }

  const chooseBlue = () => {
    setColour("blue")
  }

  const shapeColourMap = {
    "red": "text-red-500",
    "blue": "text-blue-500",
    "yellow": "text-yellow-500",
    "white": "text-white"
  }

  const submitChoice = () => {
    fetch(`${process.env.REACT_APP_API_URL}/participate`, {
      method: "POST",
      body: JSON.stringify({
        userId,
        shape,
        colour
      }),
      mode: "cors"
    }).then(() => {
      toast.success('Successfully sent!')
      setColour(null)
      setShape(null)
    }).catch(() => {
      toast.error('Whoops! That teaches me to do a live demo...')
      setColour(null)
      setShape(null)
    })
  }

  return (
    <Layout>
      <div className={"py-3 md:py-8"}>
        <div className="bg-slate-600 text-slate-300 inline-block rounded px-3 py-2 text-xl font-medium">
          User ID {userId}
        </div>

        <div className="text-slate-300 rounded text-5xl mt-10 md:mt-14 font-medium">
          Choose a shape
        </div>
        <div className={"grid grid-cols-1 sm:grid-cols-3 mt-5 gap-2 sm:gap-12 md:gap-24 w-1/2 sm:w-full"}>
          <div className={shape === "cloud" ? shapeColourMap[colour ? colour : 'white'] : "text-slate-500"} onClick={chooseCloud}>
            <Shapes.Cloud size={'12'}/>
          </div>
          <div className={shape === "ticket" ? shapeColourMap[colour ? colour : 'white'] : "text-slate-500"} onClick={chooseTicket}>
            <Shapes.Ticket size={'12'}/>
          </div>
          <div className={shape === "megaphone" ? shapeColourMap[colour ? colour : 'white'] : "text-slate-500"} onClick={chooseMegaphone}>
            <Shapes.Megaphone size={'12'}/>
          </div>
        </div>

        <div className="text-slate-300 rounded text-5xl mt-5 md:mt-12 font-medium">
          Choose a colour
        </div>
        <div className={"grid grid-cols-1 sm:grid-cols-3 mt-10 md:mt-14 text-slate-500 gap-2 sm:gap-12 md:gap-24 w-1/2 sm:w-full"}>
          <div className={"w-full aspect-square flex items-center justify-center rounded " + (colour === "red" ? "bg-red-500 text-white" : "bg-slate-500 text-black")} onClick={chooseRed}>
            <div className={"text-xl font-bold"}>RED</div>
          </div>
          <div className={"w-full aspect-square flex items-center justify-center rounded " + (colour === "yellow" ? "bg-yellow-500 text-white" : "bg-slate-500 text-black")} onClick={chooseYellow}>
            <div className={"text-xl font-bold"}>YELLOW</div>
          </div>
          <div className={"w-full aspect-square flex items-center justify-center rounded " + (colour === "blue" ? "bg-blue-500 text-white" : "bg-slate-500 text-black")} onClick={chooseBlue}>
            <div className={"text-xl font-bold"}>BLUE</div>
          </div>
        </div>

        {shape !== null && colour !== null && (
          <>
            <div className="text-slate-300 rounded text-5xl mt-10 md:mt-14 font-medium">
              Send your choice!
            </div>
            <div onClick={submitChoice} className={"bg-green-600 text-white inline-block rounded px-3 py-2 text-lg font-medium mt-5 hover:cursor-pointer"}>
              Submit
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}